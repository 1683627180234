<template>
  <a :href="company.link" target="_blank" class="companyWattermark">
    <img :src="company.logo" class="companyWattermark-image" alt="" />
    <span class="companyWattermark-title">{{ company.title }}</span>
  </a>
</template>

<script>
export default {
  name: "CompanyWattermark",
  props: ["company"],
};
</script>

<style lang="sass">
.companyWattermark
    display: flex
    flex-direction: column
    align-items: center
    justify-content: center
    position: fixed
    max-width: 180px
    width: 100%
    bottom: 10px
    left: 10px
    z-index: 3
    & .companyWattermark-image
        display: block
        width: 100%
        height: 100%
        max-height: 80px
        object-fit: contain
    & .companyWattermark-title
        display: block
        width: 100%
        color: #fff
        background: #939393
        text-align: center
        padding: 4px 0
        margin-top: 5px
        border: 1px solid transparent
        border-radius: 4px
        font-size: 12px
</style>
